<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :server-items-length="total"
    :options.sync="options"
    :loading="loading"
    :expanded.sync="expanded"
    fixed-header
    single-expand
    show-expand
    :height="tableHeight"
    @pagination="updatePage"
    @item-expanded="fetchClaimDetail"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ClaimDetailTable :claim="item"></ClaimDetailTable>
      </td>
    </template>
    <template v-slot:item.calculatedTotalClaimAmount="{ item }">
      {{ item.calculatedCalculatedTotalClaimAmount | toNumber(2, selectedProgram) }}
      {{ item.calculatedCalculatedTotalClaimAmountCurrency ? item.calculatedCalculatedTotalClaimAmountCurrency : "" }}
    </template>
    <template v-slot:item.createdDate="{ item }">
      {{ item.createdDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
    </template>
    <template v-slot:item.invoiceDate="{ item }">
      {{ item.invoiceDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
    </template>
    <template v-slot:item.claimStage.name="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.claimStage | formatStage(item) }}
          </span>
        </template>
        <span>{{ item.claimStage.description }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";
import ClaimDetailTable from "./ClaimDetailTable";

export default {
  name: "ClaimApprovedTable",
  components: {
    ClaimDetailTable
  },
  props: {
    filters: Object,
    showParticipant: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    tableHeight: null,
    headers: [
      {
        text: "Detail",
        value: "data-table-expand",
        show: true
      },
      {
        value: "createdDate",
        text: "Date Submitted",
        sortable: true,
        show: true
      },
      {
        value: "claimKey",
        text: "Claim Number",
        sortable: true,
        show: true
      },
      {
        value: "promotion.promotionKey",
        text: "Promotion Number",
        sortable: false,
        show: true
      },
      {
        value: "promotion.name",
        text: "Promotion",
        sortable: false,
        show: true
      },
      {
        value: "invoiceNumber",
        text: "Invoice Number",
        sortable: true,
        show: true
      },
      {
        value: "invoiceDate",
        text: "Invoice Date",
        sortable: true,
        show: true
      },
      {
        value: "calculatedTotalClaimAmount",
        text: "Amount",
        sortable: true,
        align: "right",
        show: true
      },
      {
        value: "claimStage.name",
        text: "Status",
        sortable: false,
        show: true
      }
    ],
    expanded: [],
    expandedClaimProducts: [],
    expandedClaimFieldValues: [],
    expandedClaimUploads: [],
    loading: false,
    total: 0,
    options: {
      sortBy: ["createdDate"],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1
    }
  }),
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    },
    filters: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  methods: {
    initialize() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = Object.assign({}, this.filters);
      filters.submitted = true;
      filters.approved = true;
      filters.closed = true;

      ApiService.post(
        "/api/claims/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        filters
      )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.$emit("totalElements", this.total);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updatePage(pagination) {
      if (pagination.itemsPerPage == 50) {
        this.tableHeight = "30vh";
      } else {
        this.tableHeight = null;
      }
    },
    fetchClaimDetail(obj) {
      this.loading = true;
      return Promise.all([
        this.fetchClaimProducts(obj.item),
        this.fetchClaimFieldValues(obj.item),
        this.fetchClaimUploads(obj.item)
      ]).finally(() => {
        this.loading = false;
      });
    },
    fetchClaimProducts(claim) {
      if (claim.id in this.expandedClaimProducts) {
        return Promise.resolve();
      }
      return ApiService.get("/api/claimProducts/byClaimId/" + claim.id).then(({ data }) => {
        this.expandedClaimProducts[claim.id] = data.content;
      });
    },
    fetchClaimFieldValues(claim) {
      this.expandedClaimFieldValues = [];
      return ApiService.get("/api/claimFieldValues/byClaimId/" + claim.id + "?size=100").then(({ data }) => {
        let claimFieldValues = data.content;
        claimFieldValues.sort((a, b) => a.claimField.rank - b.claimField.rank);
        this.expandedClaimFieldValues = claimFieldValues;
      });
    },
    fetchClaimUploads(claim) {
      this.expandedClaimUploads = [];
      return ApiService.get("/api/claims/" + claim.id + "/uploads/").then(({ data }) => {
        this.expandedClaimUploads = data._embedded.uploads;
      });
    }
  },
  mounted() {
    if (this.showParticipant) {
      this.headers.splice(1, 0, {
        value: "participant.fullName",
        text: "Participant",
        sortable: false
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"])
  }
};
</script>
